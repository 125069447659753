import React, { CSSProperties, useContext, useEffect, useState } from 'react';
import { getDateFromTimestamp, getImageKitUrlFrom, getUrlToGamePage } from '../utilities/utils';
import { PatientHomeData } from '../models/PatientHomeData';
import { Typography } from './Typography';
import { ExerciseType } from '../models/ExerciseType';
import closeBlue from '../assets/closeBlue.svg';
import { Roles } from '../models/Roles';
import AlbertAvatar from '../assets/albert.svg?react';
import { t } from 'i18next';
import edit from '../assets/edit.png';
import useWindowDimensions from '../utilities/useWindowDimensions';
import { useHistory } from 'react-router';
import { UserDataProp } from '../models/userDataProp';
import { UserContext } from '../providers/UserProvider';
import { Button } from './Button';
import { deleteExerciseCard } from '../services/cloudFirestore';
import { DeleteModal } from './DeleteModal';
import * as games from '../models/Games';
import { ProfileImage } from './ProfilImage';
import { useWindow } from '../contexts/WindowContext';
import Heading from './Heading';

const classes: { [key: string]: CSSProperties } = {
    'div-root': {
        backgroundColor: 'var(--color-white)',
        color: 'var(--vignette-games-text-color)',
        // padding: '5px 5px 8px',
        gap: '8px',
        boxShadow: '0px 4px 8px rgba(226, 199, 199, 0.7)',
        borderRadius: '8px',
        marginBottom: '1rem',
        position: 'relative',
        cursor: 'pointer'
        // maxWidth: '250px'
    },
    'div-img': {
        width: '100%',
        height: 'auto',
        position: 'relative'
        // padding: '5px 5px 8px'
    },
    'img-exercise': {
        width: '100%',
        height: 'auto',
        borderRadius: '8px 8px 0 0'
    },
    'div-type-ex': {
        position: 'absolute',
        top: '10px',
        right: '10px',
        backgroundColor: '#fff',
        padding: '0.25rem 0.25rem',
        borderRadius: '6px',
        width: '7rem',
        height: '7rem',
        fontSize: '0.75rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    'div-msg-author': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        marginBottom: '0.5rem',
        gap: '8px',
        height: '3.2rem',
        padding: '0 0.5rem'
    },
    'img-profile': {
        height: '3.2rem',
        width: '3.2rem',
        borderRadius: '50%',
        objectFit: 'cover'
    },
    'btn-edit': {
        height: '1.75rem',
        minHeight: '1.75rem',
        width: '1.75rem',
        borderRadius: '50%',
        position: 'absolute',
        bottom: '0.5rem',
        left: '0.5rem',
        backgroundColor: '#ffffff',
        zIndex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid #BABABA'
    },
    'btn-edit-img': {
        height: '1rem',
        width: '1rem'
    },
    'div-edit': {
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: '0',
        left: '0',
        zIndex: 10,
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.5)',
        padding: '0.5rem'
    },
    'btn-delete': {
        color: '#D00070',
        backgroundColor: '#f4e0eb',
        fontWeight: 'bold',
        border: 'none'
    },
    'btn-close': {
        position: 'absolute',
        top: '0.5rem',
        right: '50%',
        transform: 'translateX(50%)',
        zIndex: 11,
        height: '2.5rem',
        minHeight: '2.5rem',
        width: '2.5rem',
        borderRadius: '50%',
        backgroundColor: '#ffffff',
        border: '2px solid var(--button-bg-color)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
};

const PuzzleModalMobile = ({ onClose = () => {}}) => {
    return (
        <div style={{ padding: '1rem', textAlign: 'center', display: 'flex', flexDirection: 'column', gap: '2rem', height: '100%', justifyContent: 'center' }}>
            <div>
            <Heading style={{ fontWeight: 800, fontSize: '27px', color: 'var(--main-pink-color)' }}>Oups,</Heading>
            <Heading style={{ fontWeight: 800, fontSize: '22px' }}>Votre écran est trop petit.</Heading>
            </div>
            <div style={{ fontSize: '22px', color: 'var(--Typography-h1-color)' }}>Pour votre confort visuel, connectez-vous sur une tablette ou un ordinateur pour jouer à ce jeu.</div>
            <Button onClick={onClose} sx={{ fontWeight: 700 }}>Retour à l'accueil</Button>
        </div>
    )
}

interface VignetteGamesProps {
    itemData: PatientHomeData;
    displayExerciceType: ExerciseType | null;
    // selectGames: (itemData: PatientHomeData) => void;
    editGames: (itemData: PatientHomeData) => void;
    deleteExerciseCardFromList: (itemData: PatientHomeData) => void;
}

export function VignetteGames({
    itemData,
    displayExerciceType,
    // selectGames,
    editGames,
    deleteExerciseCardFromList
}: VignetteGamesProps): JSX.Element {
    const { height, width } = useWindowDimensions();
    const { openWindow, closeWindow } = useWindow();
    const history = useHistory();
    const [isOnEdit, setIsOnEdit] = useState<boolean>(false);
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const imgTypeExercice = [
        { [ExerciseType.MEMORIZ]: games.memory.icon },
        { [ExerciseType.PONG]: games.pingpong.icon },
        { [ExerciseType.PUZZLE]: games.puzzle.icon },
        { [ExerciseType.QUIZ]: games.quizz.icon },
        { [ExerciseType.ORDER]: games.reordon.icon },
        { [ExerciseType.SUDOKU]: games.sudok.icon },
        { [ExerciseType.TOURBILLON]: games.tourbillon.icon },
        { [ExerciseType.MELIMOTS]: games.melimots.icon }
    ];
    const [displayDeleteModal, setDisplayDeleteModal] = useState<boolean>(false);
    const [right, setRight] = useState<'write' | 'read' | null>(null);

    useEffect(() => {
        if (user && itemData.exercise.proList) {
            itemData.exercise.proList.forEach((pro) => {
                if (pro && (Object.keys(pro)[0] === user.id || Object.keys(pro)[0] === user.userId)) {
                    setRight(Object.values(pro)[0]);
                }
            });
        }
    }, [user, itemData.exercise.proList]);

    return (
        <>
            {displayDeleteModal && (
                <DeleteModal
                    type="game"
                    onClose={() => setDisplayDeleteModal(false)}
                    deleteFunc={() => {
                        if (
                            user &&
                            (itemData.author?.userId === user.userId || itemData.author?.userId === user.id) &&
                            itemData.exercise.id
                        ) {
                            deleteExerciseCard(itemData.exercise.id).then(() => {
                                setIsOnEdit(false);
                                deleteExerciseCardFromList(itemData);
                            });
                        }
                    }}
                />
            )}
            <div
                onClick={() => {
                    if (itemData.exercise.exerciseType === ExerciseType.PUZZLE && width < 1024) {
                        if (openWindow && closeWindow)
                            openWindow({
                                component: () => <PuzzleModalMobile
                                    onClose={() => {
                                        closeWindow();
                                    }}
                                />,
                            });
                        return;
                    }
                    history.push(getUrlToGamePage(itemData));
                }}
                style={{
                    ...classes['div-root'],
                    ...{
                        display:
                            !displayExerciceType ||
                                displayExerciceType === itemData.exercise.exerciseType
                                ? 'block'
                                : 'none'
                    },
                    ...(width && width < 554
                        ? { width: '100%' }
                        : width && width < 768
                            ? { width: 'calc(100% / 2 - 8px)' }
                            : width && width < 1024
                                ? { width: 'calc(100% / 3 - 12px)' }
                                : { width: 'calc(100% / 3 - 12px)' })
                }}>
                <div style={classes['div-img']}>
                    {user && (itemData.exercise.authorId === user.userId || itemData.exercise.authorId === user.id || right !== null) && (
                        <Button
                            sx={{
                                ...classes['btn-edit']
                            }}
                            onClick={(e: Event) => {
                                setIsOnEdit(true);
                                e.stopPropagation();
                            }}>
                            <img src={edit} alt="edit" style={{ ...classes['btn-edit-img'] }} />
                        </Button>
                    )}
                    {itemData && itemData.exerciseImage && (
                        <img
                            style={classes['img-exercise']}
                            src={getImageKitUrlFrom(itemData.exerciseImage, 1024, 1024)}
                            alt={itemData.exercise.exerciseName}
                        />
                    )}

                    <div style={classes['div-type-ex']}>
                        {imgTypeExercice.map((img, idx) => {
                            if (
                                itemData.exercise.exerciseType &&
                                img[itemData.exercise.exerciseType]
                            ) {
                                const GameIcon = img[itemData.exercise.exerciseType] as React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
                                return (
                                    <GameIcon key={idx} height='100%' width='100%' />
                                );
                            }
                        })}
                    </div>
                </div>
                {/* <div style={classes['div-msg']}> */}
                <Typography
                    sx={{
                        color: 'var(--vignette-games-text-color)',
                        width: '100%',
                        textAlign: 'center',
                        alignItems: 'center',
                        padding: '0.5rem 0.5rem',
                        justifyContent: 'center',
                        lineHeight: '2rem',
                        display: 'flex',
                        // height: '4.5rem',
                        fontSize: '1.4rem',
                        fontWeight: '500',
                        margin: '0.5rem 0px'
                    }}>
                    {itemData.exercise.exerciseName}
                </Typography>
                <hr
                    style={{
                        color: '#EFEFEF',
                        backgroundColor: '#EFEFEF',
                        height: '0.0625em',
                        width: '100%',
                        marginBottom: '0.5rem'
                    }}
                />
                <div style={{ ...classes['div-msg-author'], paddingTop: '0.5rem' }}>
                    {itemData.author && itemData.author.role !== Roles.ADMIN && (
                        <ProfileImage
                            width={150}
                            height={150}
                            name={itemData.author.firstName + ' ' + itemData.author.lastName}
                            url={itemData.author.profilePhoto}
                            sx={classes['img-profile']}
                        />
                    )}
                    {itemData.exercise.authorType === Roles.ADMIN && (
                        <AlbertAvatar style={{
                            height: '3.5rem',
                            width: '3.5rem',
                        }} />
                    )}
                    <Typography
                        sx={{
                            fontWeight: '600',
                            color: 'var(--main-blue-color)',
                            fontSize: '0.875rem'
                        }}>
                        {itemData.exercise.authorType === Roles.ADMIN
                            ? `${t('from')} : Albert de Stimul'in`
                            : `${t('from')} : ${itemData.author?.firstName ?? ''} ${itemData.author?.lastName ?? ''
                            }`}
                        {/* <br />
                        {`${t('receivedAt')} : ${ itemData.exercise.createdDate && user?.createdDate && itemData.exercise.createdDate > user.createdDate ? getDateFromTimestamp(itemData.exercise.createdDate) : user?.createdDate && getDateFromTimestamp(user?.createdDate)}`} */}
                    </Typography>
                </div>
                {isOnEdit &&
                    user &&
                    (itemData.author?.userId === user.id || itemData.author?.userId === user.userId || right !== null) && (
                        <div style={classes['div-edit']}>
                            <Button
                                sx={classes['btn-close']}
                                onClick={(e): void => {
                                    e.stopPropagation();
                                    setIsOnEdit(false);
                                }}>
                                <img
                                    src={closeBlue}
                                    alt="close"
                                    style={{ ...classes['btn-edit-img'] }}
                                />
                            </Button>
                            {right === 'read' && (
                                <Button
                                    outline
                                    onClick={(e): void => {
                                        e.stopPropagation();
                                        editGames(itemData);
                                    }}>
                                    {t('sendSenior')}
                                </Button>
                            )}
                            {(itemData.author?.userId === user.id || itemData.author?.userId === user.userId || right === 'write') && (
                                <Button
                                    outline
                                    onClick={(e): void => {
                                        e.stopPropagation();
                                        editGames(itemData);
                                    }}>
                                    {t('edit')}
                                </Button>
                            )}
                            {(itemData.author?.userId === user.id || itemData.author?.userId === user.userId) && (
                                <Button
                                    sx={classes['btn-delete']}
                                    onClick={(e): void => {
                                        e.stopPropagation();
                                        setDisplayDeleteModal(true);
                                    }}>
                                    {t('delete')}
                                </Button>
                            )}
                        </div>
                    )}
                {/* </div> */}
            </div>
        </>
    );
}
